module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react_a1a0c9e30966be7d688639e9bd74108f/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.14.0_zclr43skdj7vh2md5fjhxchbga/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://data.diewohnplaner.de/graphql","schema":{"perPage":5,"requestConcurrency":2,"previewRequestConcurrency":25,"timeout":60000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react_481817ec256de9d530df65658b8beecf/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-TKF5RMT","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@1_17247fd1c8cf13016571bc45600f170e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
